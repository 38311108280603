import { motion } from "motion/react";
import Image from "next/image";
import React from "react";
import { useAuth } from "ui/hooks/useAuth";
import { cn } from "ui/lib/utils";
import { AuthBanner } from "../components/auth-banner";

const SignIn = () => {
  const { handleLogin } = useAuth();

  return (
    <>
      <AuthBanner />
      <div className="bg-background flex-center w-full h-screen overflow-hidden bg-center bg-no-repeat bg-cover">
        <div
          style={{
            minHeight: 550,
          }}
          className="flex-center relative overflow-hidden h-screen md:max-h-[550px] md:max-w-[420px] items-end w-full bg-secondary text-foreground md:shadow-xl text-center md:rounded-xl md:border border-neutral-500 bg-center bg-no-repeat bg-cover"
        >
          <div className="absolute inset-0 transform -translate-x-8 rotate-12 scale-125">
            <div
              className="w-full h-full bg-cover bg-center absolute inset-0"
              style={{
                backgroundImage: `url(https://ladderteams.imgix.net/website/coaches/tiles-v1.png)`,
              }}
            />
          </div>
          <div className="relative z-10 px-6 py-4 pb-16 text-left">
            <div className="flex-center justify-start space-x-1 mb-2">
              <Image
                src="/assets/logo/white.svg"
                alt="Ladder Logo"
                width={632 * 0.15}
                height={104 * 0.15}
              />
              {/* <h1 className="text-xl">APOLLO</h1> */}
            </div>
            <p className="text-2xl md:text-3xl font-display md:leading-10 uppercase">
              Sign In To Apollo <br /> To Manage Your Team
            </p>
            <motion.button
              className={cn(
                "relative mb-2 w-full mt-4 text-center flex-center text-sm md:text-base mx-auto font-bold center space-x-2 flex px-8 py-2 text-black rounded-full lg:px-6 bg-white border border-neutral-500/50 shadow-md"
              )}
              whileHover={{ scale: 1.025 }}
              whileTap={{ scale: 0.975 }}
              onClick={() => handleLogin(`/programs`)}
            >
              <Image
                src="https://ladderteams.imgix.net/website/icons/social/apple.svg"
                alt="apple logo"
                className="w-4 h-4 text-black"
                width={16}
                height={16}
              />
              <span>{"Sign In With Apple"}</span>
            </motion.button>
          </div>
          <div className="pointer-events-none bg-gradient-to-t from-background to-transparent w-full h-full absolute bottom-0 inset-x-0" />
        </div>
      </div>
    </>
  );
};

SignIn.Layout = ({ children }) => children;
export default SignIn;
